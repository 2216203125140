import { Col, Row } from "antd";
import React from "react";

const Company = () => {
  return (
    <section className="company-section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6">
            <h2>Company</h2>
            <p className="lead">
              We focus our success on the four Cs: Confidence, Compassion,
              Credibility & Change.These values reflect our interactions with
              our clients, our peers, and most importantly our community. MHS
              works hard to ensure that each and every individual in the team
              feels seen and is held accountable for creating a company that we
              are proud to work at. Whether you are a potential customer, a
              small business partner, or a future employee, we look forward to
              coming together collectively achieving the best for everyone.
            </p>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../Assets/Images/MHS HOUSES CLOSEUP 01.jpg")}
              alt="Company image"
            />
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <h2>Corporate Social Responsibility</h2>
        <p className="lead">
          Meraj Housing Society prides itself being socially aware, taking
          inspiration from the UN Global CSR and adopting a number of its SDGs
          to transform our world:
        </p>
        <ul>
          <li>Decent work and Economic Growth</li>
          <li>Industry, Innovation and Infrastructure</li>
          <li>Quality Education</li>
          <li>Good Health and Well-being</li>
          <li>No Poverty</li>
          <li>Affordable and Clean Energy</li>
          <li>Sustainable Cities and Communities</li>
        </ul>
        <p className="lead">
          Above mentioned are some of socioeconomic and environmental goals we
          at Meraj are pursuing as a part of our corporate social responsibility
          towards the community. We aim to satisfy the need of our clients by
          delivering urban transformation that creates a meaningful value and
          contribute to socioeconomic and ecological development. Our main focus
          is on a clean green environment with a mass plantation drive, creating
          jobs and boosting the economy in the region, providing quality
          infrastructure, education and healthcare opportunities to make a
          self-sustainable community for the ages to come.
        </p>
      </div>

      <div className="container mt-5">
        <h2>What drives us?</h2>
        <p className="lead">
          MHS inherits and upholds the value what we call our “Client First
          Philosophy.” We ensure that the needs of our clients are dealt with
          the highest of our ability. Our team works efficiently with full
          motivation and devotion to make sure that all our projects are
          completed on time. MHS’s clients come to us with the confidence that
          we have the expertise and ability to take the Real Estate developments
          and construction industry to the next level of performance.
        </p>
      </div>
    </section>
  );
};

export default Company;
