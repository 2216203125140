import firebase from "firebase";
import "firebase/storage";
const firebaseDevelopment = {
  apiKey: "AIzaSyD5yeHnjb5o2I6ZJqG0AWYDhNeG0I1p8rY",
  authDomain: "billion-dollar-program.firebaseapp.com",
  projectId: "billion-dollar-program",
  storageBucket: "billion-dollar-program.appspot.com",
  messagingSenderId: "100023083693",
  appId: "1:100023083693:web:b161929df50cba4c9edc24",
  measurementId: "G-JECP4PG9D4",
};
const firebasePoroduction = {
  apiKey: "AIzaSyBKDSbTVqa27IjXXDVHEOD-_PXpmZ3tlzk",
  authDomain: "bd-program-production.firebaseapp.com",
  projectId: "bd-program-production",
  storageBucket: "bd-program-production.appspot.com",
  messagingSenderId: "818434573845",
  appId: "1:818434573845:web:65a5f49c0131a27cb5301c",
  measurementId: "G-PDM1QGMMR0",
};
// Initialize Firebase
var fireDb = firebase.initializeApp(
  firebasePoroduction
  // firebaseDevelopment
);

firebase.analytics();
const db = firebase.firestore();

const storage = firebase.storage();
const dbStorage = fireDb.database().ref();
export { storage, db, dbStorage, firebase };
