import React from "react";
import { Menu, Avatar } from "antd";
import { UserOutlined, CodeOutlined, LogoutOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/reducers/authReducer";

const RightMenu = ({ mode, showDrawer }) => {
  const history = useHistory();
  // const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <Menu mode={mode}>
      {/* <Menu.SubMenu
        title={
          <span>
            Account
            <span className="username">John Doe</span>
          </span>
        }
      ></Menu.SubMenu> */}
    </Menu>
  );
};

export default RightMenu;
