import React, { useState } from "react";
import { Menu } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
const LeftMenu = ({ mode, showDrawer }) => {
  const history = useHistory();
  const renderTitle = (title) => (
    <span>
      {title}
      <a
        style={{
          float: "right",
        }}
        href="https://www.google.com/search?q=antd"
        target="_blank"
        rel="noopener noreferrer"
      >
        more
      </a>
    </span>
  );
  const renderItem = (title, count) => ({
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        <span>
          <UserOutlined /> {count}
        </span>
      </div>
    ),
  });
  const options = [
    {
      label: renderTitle("Libraries"),
      options: [
        renderItem("AntDesign", 10000),
        renderItem("AntDesign UI", 10600),
      ],
    },
    {
      label: renderTitle("Solutions"),
      options: [
        renderItem("AntDesign UI FAQ", 60100),
        renderItem("AntDesign FAQ", 30010),
      ],
    },
    {
      label: renderTitle("Articles"),
      options: [renderItem("AntDesign design language", 100000)],
    },
  ];
  const [current, setCurrent] = useState("latest_news");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  const location = useLocation();
  const path = location.pathname;
  console.log(path.split("/")[1]);
  return (
    <Menu mode={mode} onClick={onClick} selectedKeys={[path.split("/")[1]]}>
      <Menu.Item
        key={"about"}
        onClick={() => {
          history.push("/about");
          showDrawer();
        }}
      >
        About Us
      </Menu.Item>
      {/* <Menu.Item
        key="our-"
        onClick={() => {
          history.push("/our-vision");
          showDrawer();
        }}
      >
        Our Vision
      </Menu.Item>

      <Menu.Item
        key="our-Team"
        onClick={() => {
          history.push("/our-Team");
          showDrawer();
        }}
      >
        Our Team
      </Menu.Item> */}
      <Menu.Item
        key="company"
        onClick={() => {
          history.push("/company");
          showDrawer();
        }}
      >
        Company
      </Menu.Item>
      <Menu.Item
        key="FAQs"
        onClick={() => {
          history.push("/FAQs");
          showDrawer();
        }}
      >
        FAQs
      </Menu.Item>
      <Menu.Item
        key="refund-policy"
        onClick={() => {
          history.push("/refund-policy");
          showDrawer();
        }}
      >
        Refund Policy
      </Menu.Item>
      <Menu.Item
        key="Pricing-plan"
        onClick={() => {
          history.push("/payment-plan");
          showDrawer();
        }}
      >
        Pricing-plan
      </Menu.Item>
      <Menu.Item
        key="contact"
        onClick={() => {
          history.push("/contact");
          showDrawer();
        }}
      >
        Contact us
      </Menu.Item>

      {/* <Menu.Item key="about">About Us</Menu.Item>
      <Menu.Item key="contact">Contact Us</Menu.Item> */}
    </Menu>
  );
};

export default LeftMenu;
