import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Typography } from "antd";

const { Title, Paragraph, Text } = Typography;

const RefundPolicy = () => {
  const { Title, Paragraph } = Typography;
  return (
    <div className="container mt-5 mb-5">
      <div className="refund-policy">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <>
                <Card.Body>
                  <Title level={3}>
                    Meraj Housing Society Sialkot 100% Refund Procedure –
                    Complete Guide
                  </Title>
                  <Paragraph>
                    Meraj Housing Society Sialkot has announced its new refund
                    policy for all non-possession file holders. The affectees of
                    Meraj Housing Society Sialkot will be able to get 100%
                    refund of their paid amount after submitting application for
                    refund and surrendering their file.
                  </Paragraph>
                  <Paragraph>
                    The refund policy applies to all precincts of Meraj
                    Paradise, Meraj Sports City, and other non-possession
                    precincts including old Meraj Housing Society Precincts and
                    Valley Block. The refund policy will also be applicable with
                    immediate effect to all blocked/cancelled files.
                  </Paragraph>
                  <Title level={4}>Fully Non-Possession:</Title>
                  <br />

                  <Paragraph>
                    Following precincts are fully non-possession:
                  </Paragraph>
                  <ul>
                    <li>Precinct 23A</li>
                    <li>Precinct 26</li>
                    <li>Precinct 29 (A,B,C,D)</li>
                    <li>Precinct 32 (A,B,C,D)</li>
                    <li>Precinct 33 (A,B,C,D)</li>
                    <li>Precinct 42 (Meraj Sports City)</li>
                    <li>Precinct 44 (Meraj Sports City)</li>
                    <li>Precinct 45 (Meraj Sports City)</li>
                    <li>Precinct 46 (Meraj Paradise)</li>
                    <li>Precinct 52 (Meraj Paradise)</li>
                    <li>Precinct 53 (Meraj Paradise)</li>
                    <li>Precinct 55 (Meraj Paradise)</li>
                    <li>Precinct 58 (Meraj Paradise)</li>
                    <li>Valley Block (A, B, C, D)</li>
                  </ul>
                  <br />

                  <Title level={4}>Partially Non-Possession:</Title>
                  <br />

                  <Paragraph>
                    Following precincts are partially non-possession:
                  </Paragraph>
                  <ul>
                    <li>Precinct 23</li>
                    <li>Precinct 25A</li>
                    <li>Precinct 26A</li>
                    <li>Precinct 28</li>
                    <li>Precinct 29</li>
                    <li>Precinct 32</li>
                    <li>Precinct 33</li>
                    <li>Precinct 37 (Meraj Sports City)</li>
                    <li>Precinct 38 (Meraj Sports City)</li>
                    <li>Precinct 39 (Meraj Sports City)</li>
                    <li>Precinct 40 (Meraj Sports City)</li>
                    <li>Precinct 41 (Meraj Sports City)</li>
                    <li>Precinct 42A (Meraj Sports City)</li>
                    <li>Precinct 43 (Meraj Sports City)</li>
                  </ul>
                </Card.Body>
                <br />

                <Title level={4}>Blocked/Cancelled Files:</Title>
                <br />
                <p>
                  The refund policy will also be applicable with immediate
                  effect to all blocked/cancelled files. 75% of the total paid
                  amount will be refunded.
                </p>
                <Title level={4}>Overseas Customers:</Title>
                <p>
                  Overseas Customers can also apply for the refund of their
                  amount by using the “Power of Attorney” option. To get a
                  sample of Meraj housing, kindly get in touch on WhatsApp.
                </p>
              </>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RefundPolicy;
