import React, { useEffect, useState } from "react";
import { Layout, Button, Drawer } from "antd";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import { MenuOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };

  // If you do not want to auto-close the mobile drawer when a path is selected
  // Delete or comment out the code block below
  // From here
  // let { pathname: location } = useLocation();
  // useEffect(() => {
  // setVisible(false);
  // }, [location]);
  // Upto here

  return (
    <nav className="navbar">
      <Layout>
        <Layout.Header className="nav-header">
          <div>
            {/* <h3 className="brand-font">Brand Here</h3> */}
            <Link className="logo" to="/">
              <img
                src={require("../../../Assets/Images/transparentLogo.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="navbar-menu">
            <div className="leftMenu">
              <LeftMenu mode={"horizontal"} />
            </div>
            <Button className="menuButton" type="text" onClick={showDrawer}>
              <MenuOutlined />
            </Button>
            <div className="rightMenu">
              <RightMenu mode={"horizontal"} />
            </div>

            <Drawer
              title={
                <div className="text-end">
                  {/* <h3 className="brand-font">Brand Here</h3> */}
                  <Link to="/">
                    <img
                      style={{ width: "150px" }}
                      src={require("../../../Assets/Images/transparentLogo.png")}
                      alt=""
                    />
                  </Link>
                </div>
              }
              placement="right"
              closable={true}
              onClose={showDrawer}
              visible={visible}
              style={{ zIndex: 99999 }}
            >
              <LeftMenu mode={"inline"} showDrawer={showDrawer} />

              <RightMenu mode={"inline"} showDrawer={showDrawer} />
            </Drawer>
          </div>
        </Layout.Header>
      </Layout>
    </nav>
  );
};

export default Navbar;
