import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { getDocRefByKeyValue } from "../../Firebase/utils";
import firebase from "firebase/app";
import { db } from "../../Firebase/config";

export const newsAndEvents = createAsyncThunk("newsAndEvents", async (body) => {
  try {
    let db = firebase.firestore();

    let data = [];
    let querySnapshot = await db.collection("newsAndEvents").get();
    await querySnapshot.forEach(function (doc) {
      data.push(doc.data());
    });
    return data;
  } catch (error) {
    console.log(" newsAndEvents error", error);
    return error;
  }
});
export const getEventById = createAsyncThunk("getEventById", async (uid) => {
  try {
    let res = null;
    const docRef = db.collection("newsAndEvents").doc(uid);
    await docRef.get().then((doc) => {
      if (doc.exists) {
        res = doc.data();
      }
      console.log(res, "resresresres");
    });
    return res;
  } catch (error) {
    console.log("getEventById error", error);
    return error;
  }
});
export const addAnnouncements = createAsyncThunk(
  "addAnnouncements",
  async (body) => {
    debugger;
    try {
      const data = {
        ...body,
        newsDescription: JSON.stringify(body.newsDescription),
      };
      const docRef = await firebase
        .firestore()
        .collection("newsAndEvents")
        .add(data);
      const res = await docRef.get();
      console.log({ id: docRef.id, ...res.data() }, "res add task");
      message.success("Announcements added successfully");

      return { id: docRef.id, ...res.data() };
    } catch (error) {
      console.log("add task error", error);
      return error;
    }
  }
);
const anouncmentsReducer = createSlice({
  name: "user",
  initialState: {
    token: "",
    loading: false,
    message: "",
    announcements: null,
    announcementById: null,
    success: false,
  },
  reducers: {},
  extraReducers: {
    [newsAndEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.announcements = action.payload;
    },
    [newsAndEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [addAnnouncements.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addAnnouncements.pending]: (state, action) => {
      state.loading = true;
    },

    [getEventById.fulfilled]: (state, action) => {
      console.log(action, "action");
      state.loading = false;
      state.announcementById = action.payload;
    },
    [getEventById.pending]: (state, action) => {
      state.loading = true;
    },
  },
});

export default anouncmentsReducer.reducer;
