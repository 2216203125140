import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useHistory,
  withRouter,
  Redirect,
} from "react-router-dom";
import Navbar from "../Layout/Header/Navbar";
import About from "../Pages/About";
import Company from "../Pages/Company";
import ContactUs from "../Pages/ContactUs";
import FAQs from "../Pages/FAQs";
import Home from "../Pages/Home";
import OurTeam from "../Pages/OurTeam";
import OurVision from "../Pages/OurVision";
import PaymentPlan from "../Pages/PaymentPlan";
import RefundPolicy from "../Pages/RefundPolicy";

const Routes = (props) => {
  return (
    <Switch>
      <Route path="/" exact component={Home}></Route>
      <Route path="/about" exact component={About} />
      <Route path="/our-vision" exact component={OurVision} />
      <Route path="/our-Team" exact component={OurTeam} />
      <Route path="/company" exact component={Company} />
      <Route path="/FAQs" exact component={FAQs} />
      <Route path="/refund-policy" exact component={RefundPolicy} />
      <Route path="/payment-plan" exact component={PaymentPlan} />
      <Route path="/contact" exact component={ContactUs} />

      {/* <Route path="/payment-plan" exact component={PaymentPlan} /> */}
    </Switch>
  );
};

export default withRouter(Routes);
