import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const { Title } = Typography;

const ContactUs = () => {
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <div className="container">
      <div className="contact-container mt-5">
        <Title level={2}>Contact Us</Title>
        <div className="row mt-5">
          <div className="col-md-6">
            <Form
              layout="vertical"
              onFinish={onFinish}
              className="contact-form"
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter your name!" }]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
              <Form.Item
                label="Message"
                name="message"
                rules={[
                  { required: true, message: "Please enter your message!" },
                ]}
              >
                <Input.TextArea placeholder="Enter your message" rows={5} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Submit</Button>
              </Form.Item>
            </Form>
          </div>
          <div className="col-md-6">
            <div className="contact-info-container gap-3 px-5">
              <div className="contact-info">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                <p>Meraj Housing society, Sialkot, Pakistan</p>
              </div>
              <div className="contact-info">
                <FontAwesomeIcon icon={faPhone} className="icon" />
                <p>03-111-111-647</p>
              </div>
              <div className="contact-info">
                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                <p>info@ merajhousing.com</p>
              </div>
              <div className="social-media-icons">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} className="icon" />
                </a>
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} className="icon" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} className="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
