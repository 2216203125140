import { Col } from "antd";
import React from "react";
import { Container, Row } from "react-bootstrap";

const About = () => {
  return (
    <div>
      <section className="about-us">
        <div className="container">
          <div className="row align-items-center mt-5">
            <div className="col-md-6">
              <h2>About Us</h2>
              <p>
                MERAJ, a visionary idea redefining the living standards around
                you. Backed by the Virk Group, MHS is a unique real estate
                project that separates itself from the conventional market and
                aims to bring a change in the region through its vision and
                growth.
              </p>
            </div>

            <div className="col-md-6">
              <img src={require("../Assets/Images/houses.png")} alt="" />
            </div>
          </div>
        </div>
      </section>
      <div>
        <br />

        <section id="our-vision">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 px-3">
                <img
                  src={require("../Assets/Images/MHS AERIAL 01.jpg")}
                  alt="Our Vision"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6">
                <div className="vision-content px-5">
                  <h2>Our Vision</h2>
                  <p className="fw-bold">MERAJ: a new way of life</p>
                  <p>
                    Our unique approach sets us apart from the rest of the
                    market. Believing in the extra-ordinary, MERAJ Housing
                    visualises a sustainable and ever growing lifestyle for its
                    residents, in addition to becoming a face of the whole
                    region.
                  </p>
                  <p>
                    Our main vision is to elevate the whole region through our
                    long term goals including modern business centres and high
                    class housing standards to bring a socioeconomic change for
                    the whole community. We strive to achieve this in the near
                    future working in cohesion with all the stakeholders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br />
      <div className="team-section">
        <Container>
          <h2 className="text-center mb-5 mt-5">Our Team</h2>

          <Row className="mt-5">
            <Col>
              <p className="text-center">
                We at Meraj are a dedicated group of individuals working in
                union to bring out the best for you. The goal of our team is
                transforming living standards to new heights and making them
                available readily. We believe in innovation and creativity,
                hence our team is based on energetic youth primarily coming up
                with out-of-the-box thinking and making MHS a realization of
                your dreams. Our individuals are specialists in what they do &
                bring strong credibility and ownership to the project.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;
