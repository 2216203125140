import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
// import { Row, Col, Image } from 'antd';
// import teamImage1 from "./images/team1.jpg";
// import teamImage2 from "./images/team2.jpg";
// import teamImage3 from "./images/team3.jpg";

const OurTeam = () => {
  return (
    <div className="team-section">
      <Container>
        <h2 className="text-center mb-5 mt-5">Our Team</h2>

        <Row className="mt-5">
          <Col>
            <p className="text-center">
              We at Meraj are a dedicated group of individuals working in union
              to bring out the best for you. The goal of our team is
              transforming living standards to new heights and making them
              available readily. We believe in innovation and creativity, hence
              our team is based on energetic youth primarily coming up with
              out-of-the-box thinking and making MHS a realization of your
              dreams. Our individuals are specialists in what they do & bring
              strong credibility and ownership to the project.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurTeam;
