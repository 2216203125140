import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { getDocRefByKeyValue } from "../../Firebase/utils";
import { db, firebase } from "../../Firebase/config";

export const postQuestion = createAsyncThunk("postQuestion", async (body) => {
  try {
    const resp = await firebase.firestore().collection("queries").add(body);

    console.log("add postQuestion resp!!!", resp);
    return resp;
  } catch (error) {
    console.log("add postQuestion error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});
export const getQueries = createAsyncThunk("getQueries", async (body) => {
  try {
    const posts = await firebase.firestore().collection("queries").get();
    const allPosts = [];
    posts.forEach((post) => {
      if (post.id !== "27g9Q1JzQmEZbQ8AxaBg") {
        allPosts.push({ post: post.data(), postId: post.id });
      }
    });

    console.log("add getQueries resp!!!", allPosts);
    return allPosts;
  } catch (error) {
    console.log("add getQueries error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});
export const QuestionDel = createAsyncThunk("QuestionDel", async (postId) => {
  debugger;
  try {
    const res = await db.collection("queries").doc(postId).delete();
    console.log(res);
    return res;
  } catch (error) {
    console.log(" QuestionDel error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});

export const deleteComment = createAsyncThunk(
  "deleteComment",
  async (id, postId, comments) => {
    const filteredComments = comments.filter((comment, i) => i !== id);

    try {
      const posts = await firebase
        .firestore()
        .collection("queries")
        .doc(postId)
        .update({
          comments: filteredComments,
          updatedAt: Date.now(),
        });
      console.log("add deleteComment resp!!!", { filteredComments, postId });
      return { filteredComments, postId };
    } catch (error) {
      console.log("add deleteComment error", error);
      notification.error({
        message: error.message,
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
      return error;
    }
  }
);

export const doReply = createAsyncThunk("queries", async (data) => {
  debugger;
  const reply = data.reply;
  const i = data.i;
  const postId = data.postId;
  const comments = data.comments;
  const currentComment = comments.find((comment, id) => i === id);
  // currentComment.replies = [...currentComment.replies, reply];
  let repliesCopy = [...currentComment?.replies, reply];

  const newobj = {
    comment: currentComment.comment,
    email: currentComment.email,
    user: data.user,
    replies: repliesCopy,
  };
  const updatedComments = comments.map((comment, id) =>
    id === i ? newobj : comment
  );
  try {
    const res = await firebase
      .firestore()
      .collection("queries")
      .doc(postId)
      .update({ comments: updatedComments, updatedAt: Date.now() });
    console.log("add doReply resp!!!", { id: i, postId, updatedComments });
    return { id: i, postId, updatedComments };
  } catch (error) {
    console.log("add doReply error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});
export const doComment = createAsyncThunk("queries", async (data) => {
  debugger;
  const id = data.currentPost.postId;
  const comments = data?.currentPost?.post?.comments || [];
  const newData = {
    email: data.email,
    comment: data.comment,
    replies: [],
  };
  try {
    const res = await firebase

      .firestore()
      .collection("queries")
      .doc(id)
      .update({ comments: [...comments, newData], updatedAt: Date.now() });

    console.log({ id, comment: newData });
    return { id, comment: newData };
  } catch (error) {
    console.log("add doReply error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});

const queryReducer = createSlice({
  name: "user",
  initialState: {
    success: false,
    quires: null,
  },
  reducers: {},
  extraReducers: {
    // ********************// Sign in User ***********

    [postQuestion.fulfilled]: (state, action) => {
      state.loading = false;

      // state.user = action.payload;
      notification.success({
        message: "You question has been posted successfully!",
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
    },
    [postQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [doReply.fulfilled]: (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      notification.success({
        message: "Your reply has been posted successfully!",
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
    },
    [doReply.pending]: (state, action) => {
      state.loading = true;
    },
    [doComment.fulfilled]: (state, action, as) => {
      state.loading = false;
      notification.success({
        message: "Your comment  has been posted successfully!",
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
    },
    [doComment.pending]: (state, action) => {
      state.loading = true;
    },

    [QuestionDel.fulfilled]: (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      // notification.success({
      //   message: "You Comment has been deleted successfully!",
      //   key: "logged_in",
      //   style: {
      //     borderRadius: "15px",
      //   },
      // });
    },
    [QuestionDel.pending]: (state, action) => {
      state.loading = true;
    },

    [getQueries.fulfilled]: (state, action) => {
      state.loading = false;
      state.quires = action.payload;
      // localStorage.setItem("user", action.payload.userId);
    },
    [getQueries.pending]: (state, action) => {
      state.loading = true;
    },
  },
});

export default queryReducer.reducer;
