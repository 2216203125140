import { Collapse } from "antd";
import React from "react";

const FAQs = () => {
  const { Panel } = Collapse;
  return (
    <div className="faq-container">
      <div className="container mt-5">
        <h2>Frequently Asked Questions</h2>
        <Collapse accordion defaultActiveKey={3}>
          <Panel header="What is booking of plot and how it works?" key="1">
            <p>
              The plots are being offered for sale on ownership basis. The
              allottees will own the plots after the payment of all dues and
              delivery of possession letter. Plots shall be booked on
              first-come-first-served basis. However, if the number of
              applicants shall be more than the number of plots available for
              allocation, then the allocation shall be done on the basis of
              computerized balloting. All Pakistani citizens and non-resident
              Pakistanis are eligible to apply for the offered plots. Booking
              Form will be signed both by the Applicant and the Developer. One
              Booking Form can be used for the booking of a single plot. On the
              basis of this Booking Form, Applicant cannot claim
              title/ownership/legal rights to the plot.
            </p>
          </Panel>
          <Panel
            header="What is the procedure of confirmation of the allocation?"
            key="2"
          >
            <p>
              The allocation of the plot shall be confirmed by the Developer
              through an allocation letter to the Applicant. The Allocation
              Letter shall specify the plot number, size, sector and type. The
              total price of the plot is inclusive of development charges except
              for the provision of Sui Gas, electricity, maintenance and other
              amenities, which will be charged later as determined by the
              Developer.
            </p>
          </Panel>
          <Panel header="What is payment procedure of plots?" key="3">
            <p>
              All applications for booking shall be submitted through this form
              which can be purchased for Rs. 1,000 from the Developer. The
              Booking Form needs to be duly filled in and signed by the
              Applicant. Payment for the Booking Form is non-refundable. The
              Schedule fixed for each and every instalment for the payment of
              price, dues as determined and notified by the Developer and the
              time of payment shall be the essence of the contract.
            </p>
            <p>
              All the payments for the plot shall be made by the Applicant via
              cash or pay order/demand draft/cross cheque only, drawn in the
              name of Meraj H.S. Cash payment can only be made for plots having
              price of less than Rs. 5,000,000 (Rupees Five Million Only). Cash
              payments will be made only to the Chief Executive Officer of the
              Developer or his authorized employee present physically under
              special authority letter at office premises of the Developer.
            </p>
            <p>
              The Applicant shall be liable to make the payment of instalments
              and other dues as per Schedule. If he/she fails to pay in time, a
              late payment surcharge being the pre estimate of loss will be
              levied at the rate of 20% per annum of the due amount and he/she
              shall be informed accordingly.
            </p>
          </Panel>
          <Panel header="What will be effect of preferential location?" key="4">
            <p>
              Extra/additional price shall be payable by the Applicant as per
              the Schedule for preferential location plots such as corner,
              facing park, main boulevard, corner and facing park, corner and
              main boulevard, facing park and main boulevard, corner, main
              boulevard and facing park.
            </p>
          </Panel>
          <Panel
            header="WHAT WILL HAPPENED IF THERE WILL BE CHANGE IN SIZE OF PLOT?
"
            key="5"
          >
            <p>
              The total price of the plot as per Schedule is based on per square
              foot rate subject to actual measurement of the Plot, which will be
              finally determined at the time of handing over of the possession
              of the same.
            </p>
            <p>
              The exact size of the plot shall remain tentative and subject to
              adjustment in accordance with demarcation/measurement of the said
              plot at the time of possession. If the size/area of the plot is
              increased/decreased at the time of handing over of possession,
              then the addition/difference due to such a change shall be
              accordingly adjusted. Extra payment, if any, will be paid by the
              allottee within 30 days of measurement prior to the delivery of
              possession.
            </p>
          </Panel>

          <Panel
            header="WHAT IS THE PROCEDURE OF CANCELLATION OF PLOT?
"
            key="6"
          >
            <p>
              In case of failure to pay the due instalment of price of the plot
              or other dues, demand notice of fifteen (15) days shall be served
              to the Applicant by registered post at his/her given
              correspondence address. If the payment is not received within the
              stipulated period, the Developer reserves the right to cancel the
              booking/allotment/allocation. The amount received by the Developer
              till that time shall be refunded, after confirmation of sale to
              the new buyer, deducting 30% of the price of plot as
              service/establishment charges.
            </p>
            <p>
              The cost of the plot as given in the attached payment schedule
              does not include membership fees, ownership transfer fee,
              documentation charges, connection and cost of meter charges of
              electricity, water and sewerage and others, which shall be paid by
              the allottee in cash (non-refundable) to the Developer within
              fifteen (15) days on demand.
            </p>
            <p>
              In case of change of correspondence address, the Applicant shall
              inform the Developer in writing about the change within fifteen
              (15) days. In case of recovery, default or cancellation, the
              correspondence address mentioned in the Booking Form shall be
              treated as the final address. All letters shall be sent by the
              Developer through registered post. Non-availability reported by
              the above courier service of the Applicant/Allottee at the address
              given shall be deemed as a valid service of the notice/letter.
            </p>
            <p>
              The Developer reserves the right to allot a plot, cancelled under
              this clause to any other person and the Applicant shall have no
              claim, interest, title or right to such plot.
            </p>
          </Panel>
          <Panel
            header="IF I WANT TO WITHDRAWAL OF MY ALLOCATION?
"
            key="7"
          >
            <p>
              The Applicant can withdraw his/her allocation of the plot on
              surrendering the original allocation letter to the Developer. The
              amount received by the Developer shall be refunded, after
              confirmation of sale to the new buyer, deducting 10% of the price
              of the plot as service/establishment charges.
            </p>
          </Panel>
          <Panel
            header="CAN WE TRASFER THE PLOT AND WHAT IS THE PROCESS OF TRANSFER OF PLOT?
"
            key="8"
          >
            <p>
              The plot can be transferred after clearance of outstanding dues
              payable before or on the date of transfer after the issuance of
              NDC (No Due Certificate) by the Developer. The Developer will
              charge Transfer fee at the time of transfer of the plot.
            </p>
          </Panel>
          <Panel
            header="WHEN WILL WE HAVE POSSESSION OF PLOT?
"
            key="9"
          >
            <p>
              The Allottees shall be handed over the possession of the plots
              after three years of the submission of Booking Form or on full
              payment of the price and dues whichever comes later. Developer
              undertakes to deliver the possession of plots within the
              stipulated time however the same may be delayed due to unavoidable
              circumstances beyond the control of the Developer. Allottees must
              take over possession of the plots of the Scheme within fifteen
              (15) days of the intimation of the same.
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default FAQs;
