import React from "react";

import { Row, Col, Card, Button } from "antd";
const { Meta } = Card;
const Home = () => {
  return (
    <>
      <div className="lightGrayBg heroSection">
        <div
          id="carouselExampleCaptions"
          className="carousel slide  "
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div className="carousel-inner ">
            <div className="carousel-item  active">
              <div className="container  ">
                <div className="row align-items-center">
                  <div className="col-md-6 pt-5 pb-5">
                    <div className="carousel-content d-none d-md-block">
                      <h1>Welcome to Meraj, a new way of life!</h1>
                      <p>
                        Urban development with sleek designs reflect our housing
                        standards, redefining real estate in the region.
                      </p>

                      <p>
                        Keep exploring to find more about MHS and how lifestyle
                        in general is transforming around you.
                      </p>
                      <p>
                        We remember starting with an idealistic dream and we’ve
                        come a long way in its pursuit while evolving and
                        growing into a dynamic real estate business we are
                        today.
                      </p>
                      <p>
                        Meraj will keep on striving to live up to its high
                        standards and sustained progress in the right direction
                        with your trust and collaboration.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 pt-5 pb-5">
                    <img
                      src={require("../Assets/Images/MHS GATE 01.jpg")}
                      className=" w-100"
                      alt="First slide"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container  ">
                <div className="row align-items-center">
                  <div className="col-md-6 pt-5 pb-5">
                    <div className="carousel-content d-none d-md-block">
                      <h1>Welcome to Meraj, a new way of life!</h1>
                      <p>
                        Urban development with sleek designs reflect our housing
                        standards, redefining real estate in the region.
                      </p>

                      <p>
                        Keep exploring to find more about MHS and how lifestyle
                        in general is transforming around you.
                      </p>
                      <p>
                        We remember starting with an idealistic dream and we’ve
                        come a long way in its pursuit while evolving and
                        growing into a dynamic real estate business we are
                        today.
                      </p>
                      <p>
                        Meraj will keep on striving to live up to its high
                        standards and sustained progress in the right direction
                        with your trust and collaboration.
                      </p>
                      <Button>Explore</Button>
                    </div>
                  </div>
                  <div className="col-md-6 pt-5 pb-5">
                    <img
                      src={require("../Assets/Images/MHS MOSQUE 01.jpg")}
                      className=" w-100"
                      alt="First slide"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="container">
        <h2 className="text-center mb-5 mt-5">Our Projects</h2>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              cover={
                <img
                  alt="Residential"
                  src={require("../Assets/Images/MHS COMMERCIAL 01.jpg")}
                />
              }
            >
              <Meta
                title="Residential"
                description="We are currently offering options of 5, 10 and 20 Marla plots on convenient 5 years instalment plans ensuring a smooth process for our clients. That’s not all, we are also introducing our high end designer Villas constructed with precision for an unmatched luxurious living experience with Meraj. So whether you’re looking for land to build the house of your dreams or ready to move in our Villas, we at Meraj have our doors always open for you."
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              cover={
                <img
                  alt="Residential"
                  src={require("../Assets/Images/MHS COMMERCIAL 02.jpg")}
                />
              }
            >
              <Meta
                title="Commercial"
                description="Meraj fully understands the commercial scope that comes with a major project like ours. Hence we are offering a number of commercial opportunities to assist the progression of our society and in turn your living experience with numerous resources and utilities available readily. We offer a great opportunity for different brands and other small businesses to secure a place in our centralised commercial areas. Alongside MHS is offering opportunities for the investors as well that will generate profits in the future. Moreover, we have introduced our farm houses for an even better recreational service."
              />
            </Card>
          </Col>
        </Row>
      </div>
      <div className="masterplan-container mt-5">
        <div className="container">
          <h2>Meraj’s Masterplan</h2>
          <Row gutter={[24, 24]} className="align-items-center">
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <div className="masterplan-img-container px-5">
                <img
                  style={{ borderRadius: "20px" }}
                  src={require("../Assets/Images/MHS AERIAL 01.jpg")}
                  alt="Masterplan"
                />
              </div>
            </Col>
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <div className="masterplan-text-container">
                <p>
                  Meraj’s Masterplan covers comfort and convenience as a whole,
                  developing an innovative yet minimalist smart city taking
                  residence to new highs!
                </p>
                <p>
                  Our designs are not only artistic but are linked the latest
                  infrastructure and development techniques that go around
                  nowadays. For an overall view we have focused on the spacious
                  sense of an open and wide landscape for our society.
                </p>
                <p>
                  This open space filled with greenery would be surrounded by
                  our hallmark designer Villas connected with a wide variety of
                  available amenities.
                </p>
                <p>
                  We want our residents to have the best living experience,
                  therefore in addition to hassle-free travelling, we are
                  providing modern infrastructures inspired by the minimalist
                  trend in a green and serene landscape.
                </p>
                <p>
                  We have unique plans each based on utility such as health,
                  security system and sports. Commercial zones, club houses and
                  country clubs are included in our master plan. Our society is
                  a well-gated society with state of the art security facilities
                  to ensure a safe living atmosphere.
                </p>
                <p>
                  We are also planning the best for our future in real estate
                  with business centres and economic growth in the region.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="location-section">
        <div className="container">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <h2>Location</h2>
              <p>
                Meraj Society lies at the heart of the district, located next to
                the M-11 Daska Interchange, providing hassle free commutes and
                accessibility to all prime locations with the Motorway just 250
                Ft. away!
              </p>
              <p>
                Ideally connected to the M-11 Sialkot-Lahore motorway and GT
                road, ease of access is such a turning point. Our location is
                perfect for your everyday traffic-free travel routes while also
                simultaneously providing a world away from the noise, where you
                can relax &amp; unwind at the end of the day.
              </p>
            </Col>
            <Col xs={24} sm={12}>
              <img
                style={{ borderRadius: "22px" }}
                src={require("../Assets/Images/MHS GATE 01.jpg")}
                alt="Meraj Society Location"
              />
            </Col>
          </Row>
        </div>
      </div>
      <section id="careers" className="bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2 className="text-center mb-4">Careers</h2>
              <p className="lead text-center">
                Team MHS strives for excellence and innovation. We are firm
                believers in the power of youth and its raw potential. We are
                here to provide such opportunities to young talented individuals
                allowing their fresh ideas to actually redefine the concept of
                real estate in its truest sense! Our entire team consists of
                young, energetic & creative minds & we continue to expand our
                team by engaging more & more youth. This has also given us a
                door to new perspectives, ideas & implementations.
              </p>
            </div>
            <div className="col-md-4 align-self-center text-center">
              <Button className="btn" size="large">
                Apply Now
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
