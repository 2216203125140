import { Button } from "antd";
import React from "react";

const PaymentPlan = () => {
  return (
    <div>
      <div className="pricing">
        <div className="container">
          <h2 className="pricing-heading">Choose your plan</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="pricing-card">
                <img
                  src={require("../Assets/Images/5marla.jpg")}
                  alt="pricing"
                  className="pricing-image"
                />
                <div className="pricing-body">
                  <h3 className="pricing-title">5 Marla Plan</h3>
                  <p className="pricing-text">
                    Easy installments, TMA approved safest real state investment
                    in the city with safe returns...starting from 20 thousand
                    per month inclusive of all tax charges
                  </p>
                  <h4 className="pricing-price">Rs 20,000/month</h4>
                  <Button variant="primary" className="apply-now-btn">
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pricing-card">
                <img
                  src={require("../Assets/Images/10marla.jpg")}
                  alt="pricing"
                  className="pricing-image"
                />
                <div className="pricing-body">
                  <h3 className="pricing-title">10 Marla Plan</h3>
                  <p className="pricing-text">
                    Easy installments, TMA approved safest real state investment
                    in the city with safe returns...starting from 40 thousand
                    per month inclusive of all tax charges
                  </p>
                  <h4 className="pricing-price">Rs 40,00/month</h4>
                  <Button variant="primary" className="apply-now-btn">
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
