import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { getDocRefByKeyValue } from "../../Firebase/utils";
import { db, firebase } from "../../Firebase/config";

export const getUsersTasks = createAsyncThunk(
  "getUsersTasks",
  async (userId) => {
    try {
      const res = await firebase
        .firestore()
        .collection("tasks")
        .where("assignee", "array-contains", userId)
        .get()
        .then((querySnapshot) => {
          let tasks = [];
          querySnapshot.forEach((doc) => {
            tasks.push({ ...doc.data(), id: doc.id });
          });
          return tasks;
        });
      console.log(res);
      return res;
    } catch (error) {
      console.error("Error getting tasks: ", error);
      return [];
    }
  }
);
export const addTask = createAsyncThunk("addTask", async (task) => {
  try {
    const docRef = await firebase.firestore().collection("tasks").add(task);
    const res = await docRef.get();
    console.log({ id: docRef.id, ...res.data() }, "res add task");
    message.success("Task added successfully");

    return { id: docRef.id, ...res.data() };
  } catch (error) {
    console.log("add task error", error);
    return error;
  }
});
export const filterTasks = createAsyncThunk("filterTasks", async (body) => {
  try {
    const lecturesRef = await db.collection("tasks");
    // .where("assignee", "array-contains", "eRizOygG0MOn1RNgXJ8wVEjykVC2")
    // .get();
    var filteredLectures = lecturesRef;
    if (body.updatedAt) {
      const date = new Date(body.updatedAt);
      const timestamp = date.getTime();
      filteredLectures = filteredLectures.where(
        "assignee",
        "array-contains",
        body.userId
      );

      filteredLectures = filteredLectures.where("updatedAt", "==", timestamp);
    }
    if (body.taskTitle) {
      filteredLectures = filteredLectures.where(
        "assignee",
        "array-contains",
        body.userId
      );
      filteredLectures = filteredLectures.where(
        "taskTitle",
        ">",
        body.taskTitle.toUpperCase()
      );
    }
    const filteredData = await filteredLectures.get();
    const data = [];
    filteredData.forEach(function (doc) {
      data.push(doc.data());
      console.log(doc.data());
      // return doc.data();
    });
    console.log(data, "filter task");

    return data;
  } catch (error) {
    console.log(" filterTasks error", error);
    return error;
  }
});
export const getAllUsers = createAsyncThunk("getAllUsers", async (userId) => {
  try {
    const res = await firebase
      .firestore()
      .collection("users")
      // .where("userRole", "==", "student")
      .get()
      .then((querySnapshot) => {
        var tasks = [];
        querySnapshot.forEach((doc) => {
          tasks.push(doc.data());
        });
        return tasks;
      });
    console.log(res, "task res");
    return res;
  } catch (error) {
    console.log("tasks  error", error);
    return error;
  }
});
export const QuestionDel = createAsyncThunk("QuestionDel", async (postId) => {
  try {
    const res = await db.collection("queries").doc(postId).delete();
    console.log(res);
    return res;
  } catch (error) {
    console.log(" QuestionDel error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});

export const deleteComment = createAsyncThunk(
  "deleteComment",
  async (id, postId, comments) => {
    const filteredComments = comments.filter((comment, i) => i !== id);

    try {
      const posts = await firebase
        .firestore()
        .collection("queries")
        .doc(postId)
        .update({
          comments: filteredComments,
          updatedAt: Date.now(),
        });
      console.log("add deleteComment resp!!!", { filteredComments, postId });
      return { filteredComments, postId };
    } catch (error) {
      console.log("add deleteComment error", error);
      notification.error({
        message: error.message,
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
      return error;
    }
  }
);

export const doReply = createAsyncThunk("queries", async (data) => {
  const reply = data.reply;
  const i = data.reply;
  const postId = data.post;
  const comments = data.comments;
  const currentComment = comments.find((comment, id) => i === id);
  currentComment.replies = [...currentComment.replies, reply];
  const updatedComments = comments.map((comment, id) =>
    id === i ? currentComment : comment
  );

  try {
    const res = await firebase
      .firestore()
      .collection("lecture")
      .doc(postId)
      .update({ comments: updatedComments, updatedAt: Date.now() });
    console.log("add doReply resp!!!", { id: i, postId, updatedComments });
    return { id: i, postId, updatedComments };
  } catch (error) {
    console.log("add doReply error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});
export const doComment = createAsyncThunk("queries", async (data) => {
  const id = data.currentPost.postId;
  const comments = data?.currentPost?.post?.comments || [];
  const newData = {
    email: data.email,
    comment: data.comment,
    replies: [],
  };
  try {
    const res = await firebase

      .firestore()
      .collection("queries")
      .doc(id)
      .update({ comments: [...comments, newData], updatedAt: Date.now() });

    console.log({ id, comment: newData });
    return { id, comment: newData };
  } catch (error) {
    console.log("add doReply error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});

const taskReducer = createSlice({
  name: "user",
  initialState: {
    success: false,
    tasks: null,
    allUsers: null,
  },
  reducers: {},
  extraReducers: {
    // ********************// Sign in User ***********

    [doReply.fulfilled]: (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      notification.success({
        message: "Your reply has been posted successfully!",
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
    },
    [doReply.pending]: (state, action) => {
      state.loading = true;
    },
    [doComment.fulfilled]: (state, action, as) => {
      state.loading = false;
      notification.success({
        message: "Your comment  has been posted successfully!",
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
    },
    [doComment.pending]: (state, action) => {
      state.loading = true;
    },

    [QuestionDel.fulfilled]: (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      // notification.success({
      //   message: "You Comment has been deleted successfully!",
      //   key: "logged_in",
      //   style: {
      //     borderRadius: "15px",
      //   },
      // });
    },
    [QuestionDel.pending]: (state, action) => {
      state.loading = true;
    },

    [getUsersTasks.fulfilled]: (state, action) => {
      state.loading = false;
      state.tasks = action.payload;
    },
    [getUsersTasks.pending]: (state, action) => {
      state.loading = true;
    },
    [filterTasks.fulfilled]: (state, action) => {
      state.loading = false;
      state.tasks = action.payload;
    },
    [filterTasks.pending]: (state, action) => {
      state.loading = true;
    },

    [addTask.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addTask.pending]: (state, action) => {
      state.loading = true;
    },

    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.allUsers = action.payload;
    },
    [getAllUsers.pending]: (state, action) => {
      state.loading = true;
    },
  },
});

export default taskReducer.reducer;
