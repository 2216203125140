import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p>© 2023 Meraj Housing Society. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
