import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Alert, message, notification } from "antd";
import { useHistory } from "react-router-dom";
// import { Auth } from "aws-amplify";
import { db, firebase } from "../../Firebase/config";

export const addCategory = createAsyncThunk("addCategory", async (body) => {
  console.log(body);
  try {
    const resp = await firebase
      .firestore()
      .collection("categories")
      .add({ categoryName: body });

    console.log("add category resp!!!", resp);
    return resp;
  } catch (error) {
    console.log("add category error", error);
    return error;
  }
});
export const doLectureReply = createAsyncThunk(
  "doLectureReply",
  async (data) => {
    const reply = data.reply;
    const i = data.i;
    const uid = data.uid;
    let comments = data.comments;
    const commentsCopy = comments.slice();
    let currentComment = commentsCopy.find((comment, id) => i === id);
    let repliesCopy = [...currentComment?.replies, reply];

    const newobj = {
      comment: currentComment.comment,
      email: currentComment.email,
      user: currentComment.user,
      replies: repliesCopy,
    };
    console.log(newobj);
    const updatedComments = commentsCopy.map((comment, id) =>
      id === i ? newobj : comment
    );
    try {
      const res = await firebase
        .firestore()
        .collection("lectures")
        .doc(uid)
        .update({ comments: updatedComments, updatedAt: Date.now() });
      console.log("add doLectureReply resp!!!", {
        id: i,
        uid,
        updatedComments,
      });
      return { id: i, uid, updatedComments };
    } catch (error) {
      console.log("add doLectureReply error", error);
      notification.error({
        message: error.message,
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
      return error;
    }
  }
);
export const doVideoComment = createAsyncThunk(
  "doVideoComment",
  async (data) => {
    const id = data.currentPost.uid;
    const comments = data?.currentPost?.comments || [];
    const newData = {
      email: data.email,
      user: data.user,
      comment: data.comment,
      replies: [],
    };
    try {
      const res = await firebase

        .firestore()
        .collection("lectures")
        .doc(id)
        .update({ comments: [...comments, newData], updatedAt: Date.now() });
      return { id, comment: newData };
    } catch (error) {
      console.log("add  error", error);
      notification.error({
        message: error.message,
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
      return error;
    }
  }
);
export const addLecture = createAsyncThunk("addLecture", async (body) => {
  try {
    const resp = await firebase.firestore().collection("lectures").add(body);
    const uid = resp.id;

    console.log("addLecture uid!!!", uid);
    return { uid };
  } catch (error) {
    console.log("addLecture error", error);
    return error;
  }
});
export const getAllLectures = createAsyncThunk(
  "getAllLectures",
  async (batchId) => {
    //
    try {
      let db = firebase.firestore();

      let data = [];
      let querySnapshot = await db
        .collection("lectures")
        .where("batchId", "==", batchId)
        .get();
      await querySnapshot.forEach(function (doc) {
        data.push(doc.data());
      });
      return data;
    } catch (error) {
      console.log(" getAllLectures error", error);
      return error;
    }
  }
);
export const filterQuiz = createAsyncThunk("filterQuiz", async (body) => {
  try {
    const lecturesRef = await db.collection("quizes");
    // .where("assignee", "array-contains", "eRizOygG0MOn1RNgXJ8wVEjykVC2")
    // .get();
    var filteredLectures = lecturesRef;
    if (body.updatedAt) {
      const date = new Date(body.updatedAt);
      const timestamp = date.getTime();
      filteredLectures = filteredLectures.where(
        "assignee",
        "array-contains",
        body.userId
      );

      filteredLectures = filteredLectures.where("updatedAt", "==", timestamp);
    }
    if (body.taskTitle) {
      filteredLectures = filteredLectures.where(
        "assignee",
        "array-contains",
        body.userId
      );
      filteredLectures = filteredLectures.where(
        "taskTitle",
        ">",
        body.taskTitle.toUpperCase()
      );
    }
    const filteredData = await filteredLectures.get();
    const data = [];
    filteredData.forEach(function (doc) {
      data.push(doc.data());
      console.log(doc.data());
      // return doc.data();
    });
    console.log(data, "filter task");

    return data;
  } catch (error) {
    console.log(" filterTasks error", error);
    return error;
  }
});
export const getQuizbyBatchId = createAsyncThunk(
  "getAllLectures",
  async (batchId) => {
    debugger;
    try {
      let db = firebase.firestore();

      let data = [];
      let querySnapshot = await db
        .collection("quizes")
        .where("batchId", "array-contains", batchId)
        .get();
      await querySnapshot.forEach(function (doc) {
        data.push(doc.data());
      });
      return data;
    } catch (error) {
      console.log(" getAllLectures error", error);
      return error;
    }
  }
);
export const getLecturesForAdmin = createAsyncThunk(
  "getLecturesForAdmin",
  async () => {
    //
    try {
      let db = firebase.firestore();

      let data = [];
      let querySnapshot = await db.collection("lectures").get();
      await querySnapshot.forEach(function (doc) {
        data.push(doc.data());
      });
      return data;
    } catch (error) {
      console.log(" getLecturesForAdmin error", error);
      return error;
    }
  }
);
export const getAllResources = createAsyncThunk("getAllResources", async () => {
  //
  try {
    let db = firebase.firestore();
    let data = [];
    let querySnapshot = await db.collection("resources").get();
    await querySnapshot.forEach(function (doc) {
      data.push(doc.data());
    });
    return data;
  } catch (error) {
    console.log(" getAllResources error", error);
    return error;
  }
});
export const filterLectures = createAsyncThunk(
  "filterLectures",
  async (body) => {
    try {
      const lecturesRef = await db.collection("lectures");
      var filteredLectures = lecturesRef;
      if (body.category)
        filteredLectures = filteredLectures.where(
          "category",
          ">",
          body.category.toLowerCase()
        );
      if (body.updatedAt) {
        const date = new Date(body.updatedAt);
        const timestamp = date.getTime();
        filteredLectures = filteredLectures.where("updatedAt", "==", timestamp);
      }
      if (body.videoTitle)
        filteredLectures = filteredLectures.where(
          "videoTitle",
          ">",
          body.videoTitle.toUpperCase()
        );
      const filteredData = await filteredLectures.get();
      const data = [];
      filteredData.forEach(function (doc) {
        data.push(doc.data());
        console.log(doc.data());
        // return doc.data();
      });
      console.log(data, "datadatadatadatadatadatadata");

      return data;
    } catch (error) {
      console.log(" filterLectures error", error);
      return error;
    }
  }
);
export const getLectureById = createAsyncThunk(
  "getLectureById",
  async (uid) => {
    try {
      let res = null;
      const docRef = db.collection("lectures").doc(uid);
      await docRef.get().then((doc) => {
        if (doc.exists) {
          res = doc.data();
        }
        console.log(res, "resresresres");
      });
      return res;
    } catch (error) {
      console.log("getLectureById error", error);
      return error;
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "getAllCategories",
  async (body) => {
    let arr = [];
    console.log(body);
    try {
      const resp = await firebase.firestore().collection("categories").get();
      resp.forEach((doc) => {
        let obj = doc.data();
        obj.id = doc.id;
        arr.push(obj);
      });
      console.log("getAllCategories resp!!!", arr);

      return arr;
    } catch (error) {
      console.log("getAllCategories error", error);
      return error;
    }
  }
);

const coursesReducer = createSlice({
  name: "user",
  initialState: {
    success: false,
    courseCategories: [],
    lectures: null,
    lectureById: null,
    resources: null,
    userQuiz: null,
  },
  reducers: {},
  extraReducers: {
    // ********************// Sign in User ***********

    [addCategory.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action, "action");
    },
    [addCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [addLecture.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action, "addLecture action");
    },
    [addLecture.pending]: (state, action) => {
      state.loading = true;
    },
    [doLectureReply.fulfilled]: (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      notification.success({
        message: "Your reply has been posted successfully!",
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
    },
    [doLectureReply.pending]: (state, action) => {
      state.loading = true;
    },

    [filterLectures.fulfilled]: (state, action) => {
      state.loading = false;
      state.lectures = action.payload;
    },
    [filterLectures.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllLectures.fulfilled]: (state, action) => {
      state.loading = false;
      state.lectures = action.payload;
    },
    [getAllLectures.pending]: (state, action) => {
      state.loading = true;
    },
    [getLecturesForAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.lectures = action.payload;
    },
    [getLecturesForAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllResources.fulfilled]: (state, action) => {
      state.loading = false;
      state.resources = action.payload;
    },
    [getAllResources.pending]: (state, action) => {
      state.loading = true;
    },

    [getLectureById.fulfilled]: (state, action) => {
      state.loading = false;
      state.lectureById = action.payload;
    },
    [getLectureById.pending]: (state, action) => {
      state.loading = true;
    },
    [getQuizbyBatchId.fulfilled]: (state, action) => {
      state.loading = false;
      state.userQuiz = action.payload;
    },
    [getQuizbyBatchId.pending]: (state, action) => {
      state.loading = true;
    },

    [doVideoComment.fulfilled]: (state, action) => {
      state.loading = false;
      notification.success({
        message: "Your comment  has been posted successfully!",
        key: "logged_in",
        style: {
          borderRadius: "15px",
        },
      });
    },
    [doVideoComment.pending]: (state, action) => {
      state.loading = true;
    },

    [getAllCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseCategories = action.payload;
      console.log(action, "action", state);
    },
    [getAllCategories.pending]: (state, action) => {
      state.loading = true;
      state.courseCategories = action.payload;
    },
  },
});

export default coursesReducer.reducer;
