import Routes from "./Routes/Routes";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./Layout/Header/Navbar";
import Footer from "./Layout/Footer/Footer";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes />
        <br />
        <br />
        <br />
        <br />

        <br />

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
