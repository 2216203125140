import { configureStore } from "@reduxjs/toolkit";
import anouncmentsReducer from "./reducers/anouncmentsReducer";
import authReducer from "./reducers/authReducer";
import coursesReducer from "./reducers/coursesReducer";
import queryReducer from "./reducers/queryReducer";
import taskReducer from "./reducers/taskReducer";
export const store = configureStore({
  reducer: {
    course: coursesReducer,
    user: authReducer,
    query: queryReducer,
    announcements: anouncmentsReducer,
    tasks: taskReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
