import firebase from "firebase";
import { db, auth, storage } from "../Firebase/config";
import { message } from "antd";
export const fetchCategories = () => async (dispatch) => {
  let arr = [];
  await firebase
    .firestore()
    .collection("categories")
    .get()
    .then((allDocs) => {
      allDocs.forEach((doc) => {
        let obj = doc.data();
        obj.id = doc.id;
        arr.push(obj);
      });
    })
    .catch((e) => console.log(e));
};
export const addCategory = (val) => async (dispatch) => {
  debugger;
  alert("sd");
  const values = {
    name: val,
  };

  await firebase
    .firestore()
    .collection("categories")
    .add(values)
    .then(async () => {
      message.success("Category Created!");
      await dispatch(fetchCategories());
      return 200;
    })
    .catch((error) => {
      console.log(error);
      message.error("Error Creating Category!");
    });
};
/**
 * @description Delete images from firebase storage
 * @param {Array} imageUrlArray Array of image urls to delete
 */
export const deleteStorageFiles = async (imageUrlArray) => {
  if (!Array.isArray(imageUrlArray)) {
    console.log("Parameter must be array.");
    return false;
  }
  let arr = [...imageUrlArray];
  for (let i = 0; i < arr.length; i++) {
    await storage
      .refFromURL(arr[i])
      .delete()
      .then(() => {
        console.log("File deleted.");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
};
// ------------Add / Update--------------
export const multiImageUpload = async (path = "Products", imageFileList) => {
  let imagesUrlArray = [];
  let imageUrl = "";
  // array of files
  let arr = imageFileList.map((item) => {
    return item.originFileObj;
  });

  for (let i = 0; i < arr.length; i++) {
    const upload = await storage.ref(`/${path}/${arr[i].name}`).put(arr[i]);
    imageUrl = await upload.ref.getDownloadURL();
    imagesUrlArray.push(imageUrl);
  }

  return imagesUrlArray; // array of URLS of uploaded files
};
export async function saveData(collection, doc, jsonObject) {
  await firebase
    .firestore()
    .collection(collection)
    .doc(doc)
    .set(jsonObject, { merge: true })
    .catch(function (error) {
      console.error("Error writing document: ", error);
      return false;
    })
    .then(() => {
      return true;
    });
}

export async function saveDataWithoutDocId(collection, jsonObject) {
  const docRef = await db.collection(collection).doc();
  jsonObject.id = docRef.id;
  docRef.set(jsonObject);
  return docRef;
}

export const updateDoc = async (collection, doc, field) => {
  let updated = false;
  await firebase
    .firestore()
    .collection(collection)
    .doc(doc)
    .update(field)
    .catch(function (error) {
      console.error("Error writing document: ", error);
      updated = false;
    })
    .then(() => {
      updated = true;
    });
  return updated;
};

export async function addToArray(collection, doc, array, value) {
  let docRef = await db.collection(collection).doc(doc);
  let docData = await docRef.get();
  if (docData.exists && docData.data()[array] != undefined) {
    docRef.update({
      [array]: firebase.firestore.FieldValue.arrayUnion(value),
    });
  } else {
    saveData(collection, doc, { [array]: [value] });
  }
}

export async function addToArray2(collection, doc, array, value, value2) {
  let docRef = await db.collection(collection).doc(doc);
  let docData = await docRef.get();
  if (docData.exists && docData.data()[array] != undefined) {
    docRef.update({
      [array]: firebase.firestore.FieldValue.arrayUnion(value),
      [array]: firebase.firestore.FieldValue.arrayUnion(value2),
    });
  } else {
    saveData(collection, doc, { [array]: [value][value2] });
  }
}

export async function addToArrayUpdate(collection, doc, array, value) {
  let docRef = await db.collection(collection).doc(doc);
  let docData = await docRef.get();
  if (docData.exists && docData.data()[array] != undefined) {
    docRef.set({
      [array]: firebase.firestore.FieldValue.arrayUnion(value),
    });
  }
}

export async function updateArray(collection, doc, array, value, index) {
  let docRef = await db.collection(collection).doc(doc);
  let docData = await docRef.get();

  if (docData.exists && docData.data()[array][index] != undefined) {
    docRef
      .update({
        [array]: firebase.firestore.FieldValue.arrayRemove(
          docData.data()[array][index]
        ),
      })
      .then(async () => {
        let docRef1 = await db.collection(collection).doc(doc);
        let docData1 = await docRef1.get();
        if (docData1.exists && docData1.data()[array] != undefined) {
          docRef1.update({
            [array]: firebase.firestore.FieldValue.arrayUnion(value),
          });
        }
      });
  }
}

// ------------Get--------------

export async function getAllOfCollection(
  collection,
  page,
  filter = false,
  filterCondition,
  ProductPerPage
) {
  let data = [];
  let querySnapshot = null;

  if (filter) {
    querySnapshot = await firebase
      .firestore()
      .collection(collection)
      .where("userid", "!=", filterCondition)
      .get();
  } else {
    querySnapshot = await firebase
      .firestore()
      .collection(collection)
      .limit(page)
      .get();
  }
  querySnapshot.forEach(function (doc) {
    if (doc.exists) {
      data.push({ ...doc.data(), id: doc.id });
    } else {
      console.log("No document found!");
    }
  });
  return data;
}

export function getData(collection, doc, objectKey) {
  // check if data exists on the given path
  if (objectKey === undefined) {
    return db
      .collection(collection)
      .doc(doc)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          return doc.data();
        } else {
          return false;
        }
      });
  } else {
    return db
      .collection(collection)
      .doc(doc)
      .get()
      .then(function (doc) {
        if (doc.exists && doc.data()[objectKey] != undefined) {
          return doc.data()[objectKey];
        } else {
          return false;
        }
      });
  }
}

export async function getDocRefByKeyValue(collection, key, value) {
  return db
    .collection(collection)
    .where(key, "==", value)
    .get()
    .then(function (querySnapshot) {
      // console.log("doc id=>", querySnapshot.docs.getData());

      return querySnapshot.docs[0];
    });
}

export const getDocById = async (collection, id) => {
  const docRef = db.collection(collection).doc(id);
  const data = await docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        console.log("No such document!");
        return;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  return data;
};

export async function getDocByKeyValue(collection, key, value) {
  let db = firebase.firestore();
  let data = [];
  let querySnapshot = await db
    .collection(collection)
    .where(key, "==", value)
    .get();
  await querySnapshot.forEach(function (doc) {
    data.push({ ...doc.data(), id: doc.id });
  });
  return data;
}

export async function getDocByValueV2(collection, key1, value1, key2, value2) {
  let db = firebase.firestore();

  let data = [];
  let querySnapshot = await db
    .collection(collection)
    .where(key1, "==", value1)
    .where(key2, "==", value2)
    .get();
  await querySnapshot.forEach(function (doc) {
    data.push(doc.data());
  });
  return data;
}

export async function getDoc(collection) {
  let db = firebase.firestore();

  let data = [];
  let querySnapshot = await db.collection(collection).get();
  await querySnapshot.forEach(function (doc) {
    data.push(doc.data());
  });
  return data;
}

export async function getDocByValueAndUpdate(collection, docid, key, value) {
  let db = firebase.firestore();
  return await db.collection(collection).doc(docid).update(key, value);
}
export async function updateFollowers(collection, docid, key, value) {
  let db = firebase.firestore();
  return await db
    .collection(collection)
    .doc(docid)
    .update(key, [...value]);
}
export async function updateStats(collection, docid, key, value) {
  let updated = false;
  let db = firebase.firestore();
  return await db
    .collection(collection)
    .doc(docid)
    .update(key, value)
    .catch(function (error) {
      console.error("Error writing document: ", error);
      return (updated = false);
    })
    .then(() => {
      return (updated = true);
    });
  // return updated;
}
export async function updateDocById(collection, docid, value) {
  let db = firebase.firestore();
  return await db.collection(collection).doc(docid).update(value);
}

export async function getDocByKeyValuev2(collection, key, value) {
  let data;
  let querySnapshot = await db
    .collection(collection)
    .where(key, "==", value)
    .get();
  await querySnapshot.forEach(function (doc) {
    // console.log('doc id=>',doc.id)
    data = doc.data();
  });
  return data;
}

export async function getDocByKeyValueOR(collection, key, value) {
  let data = [];
  let querySnapshot = await db
    .collection(collection)
    .where(key, "in", value)
    .get();
  await querySnapshot.forEach(function (doc) {
    // console.log('doc id=>',doc.id)
    data.push(doc.data());
  });
  return data;
}
export async function getDocByIdArrayContains(collection, key, value) {
  let data = [];
  let querySnapshot = await db
    .collection(collection)
    .where(key, "array-contains", value)
    .get();
  await querySnapshot?.forEach(function (doc) {
    // console.log('doc id=>',doc.id)
    data.push(doc.data());
  });
  return data;
}

export async function downloadImage(folder, imageName) {
  var storageRef = storage.ref();
  var pathRef = storageRef.child(folder + "/" + imageName);

  let url = await pathRef.getDownloadURL();
  return url;
}

export async function fetchAllProducts(collection) {
  let products = [];
  await firebase
    .firestore()
    .collection(collection)
    .get()
    .then((allProducts) => {
      allProducts.forEach((item) => {
        let obj = item.data();
        obj.id = item.id;
        products.push(obj);
      });
    });
  return products;
}
export async function fetchCollections(collection, page) {
  let collections = [];
  await firebase
    .firestore()
    .collection(collection)
    .limit(page)
    .get()
    .then((allcollections) => {
      allcollections.forEach((item) => {
        let obj = item.data();
        obj.id = item.id;
        collections.push(obj);
      });
    });
  return collections;
}

// ------------Delete--------------
export async function removeItemfromArray(collection, doc, array, index) {
  let docRef = await db.collection(collection).doc(doc);
  let docData = await docRef.get();

  if (docData.exists && docData.data()[array][index] != undefined) {
    docRef.update({
      [array]: firebase.firestore.FieldValue.arrayRemove(
        docData.data()[array][index]
      ),
    });
  }
}

export async function removeItemfromArrayValue(collection, doc, array, value) {
  let docRef = await db.collection(collection).doc(doc);
  let docData = await docRef.get();

  if (docData.exists && docData.data()[array] != undefined) {
    docRef.update({
      [array]: firebase.firestore.FieldValue.arrayRemove(value),
    });
  }
}

export async function deleteDoc(collection, doc) {
  let db = firebase.firestore();
  await db
    .collection(collection)
    .doc(doc)
    .delete()
    .catch(function (error) {
      console.error("Error removing document: ", error);
    });
}

// ---------Images-----------------
export const singleImageUpload = async (path, imageFile) => {
  const upload = await storage.ref(`/${path}/${imageFile.name}`).put(imageFile);
  const imageUrl = await upload.ref.getDownloadURL();
  return imageUrl;
};
export async function uriToBlob(uri) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      // return the blob
      resolve(xhr.response);
    };

    xhr.onerror = function () {
      // something went wrong
      reject(new Error("uriToBlob failed"));
    };
    // this helps us get a blob
    xhr.responseType = "blob";
    xhr.open("GET", uri, true);

    xhr.send(null);
  });
}
export async function UpdloadImageToDb(response) {
  var today = new Date();
  var mili = today.getMilliseconds();
  let kk = Date.parse(today);
  kk = kk + mili;
  let progress = 0;
  let file = await uriToBlob(response.uri);
  response.fileName = kk + response.fileName;
  let url = null;
  const uploadTask = storage.ref(`StarImage/${response.fileName}`).put(file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      if (progress == 100) {
        console.log("progress", progress);
      }
    },
    (error) => {
      console.log("error 1", error);
    },
    async () => {
      return await downloadImage("StarImage", response.fileName);
    }
  );
}

export async function uploadImage(uri, name) {
  try {
    const response = await fetch(uri);
    const blob = await response.blob();
    const ref = storage.ref(name);
    const task = ref.put(blob);
    return new Promise((resolve, reject) => {
      task.on(
        "state_changed",
        () => {},
        (err) => {
          reject(err);
        },

        async () => {
          const url = await task.snapshot.ref.getDownloadURL();
          resolve(url);
        }
      );
    });
  } catch (err) {
    console.log("uploadImage error: " + err.message);
  }
}

export const numberFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};
