import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { getDocRefByKeyValue } from "../../Firebase/utils";
import firebase from "firebase/app";

export const signinUser = createAsyncThunk("signinUser", async (body) => {
  const { email, password } = body;

  try {
    const resp = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const id = resp.user.uid;
    const newresp = await getDocRefByKeyValue("users", "userId", id)
      .then((res) => {
        if (res.exists) {
          notification.success({
            message: "User Logged in!",
            key: "logged_in",
            style: {
              borderRadius: "15px",
            },
          });
        } else {
          window.location.pathname = "/login";
        }
        return res.data();
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message:
            "There is no user record corresponding to this identifier. The user may have been deleted.",
          key: "logged_in",
          style: {
            borderRadius: "15px",
          },
        });
      });
    console.log("add signinUser resp!!!", newresp);
    return newresp;
  } catch (error) {
    console.log("add signinUser error", error);
    notification.error({
      message: error.message,
      key: "logged_in",
      style: {
        borderRadius: "15px",
      },
    });
    return error;
  }
});
export const doReply = (i, postId, comments, reply) => (dispatch) => {
  // console.log(id, comments, name, email, reply);
  const currentComment = comments.find((comment, id) => i === id);
  currentComment.replies = [...currentComment.replies, reply];
  const updatedComments = comments.map((comment, id) =>
    id === i ? currentComment : comment
  );
  firebase
    .firestore()
    .collection("posts")
    .doc(postId)
    .update({ comments: updatedComments, updatedAt: Date.now() })
    .then(async () => {
      dispatch(addReply({ id: i, postId, updatedComments }));
    });
};
const addReply = (data) => ({
  type: "ADD_REPLY",
  payload: data,
});
export const logout = createAsyncThunk("logout", async (user_id) => {
  try {
    // console.log("logout  resp!!!", resp);
    return true;
  } catch (error) {
    console.log("logout  error", error);
    return error;
  }
});
export const getMyProfile = createAsyncThunk("getMyProfile", async (body) => {
  try {
    const newresp = await getDocRefByKeyValue("users", "userId", body.id).then(
      (res) => {
        if (res.exists) {
          return res.data();
        } else {
          localStorage.removeItem();
          window.location.path = "/login";
        }
      }
    );
    console.log(" getMyProfile resp!!!", newresp);
    return newresp;
  } catch (error) {
    window.location.path = "/login";
    localStorage.removeItem();

    console.log(" getMyProfile error", error, body);
    return error;
  }
});
const authReducer = createSlice({
  name: "user",
  initialState: {
    token: "",
    loading: false,
    message: "",
    user: null,
    success: false,
  },
  reducers: {},
  extraReducers: {
    // ********************// Sign in User ***********

    [signinUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;

      if (action.payload) {
        localStorage.setItem("user", JSON.stringify(action.payload));
      }
    },
    [signinUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getMyProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      // localStorage.setItem("user", action.payload.userId);
    },
    [getMyProfile.pending]: (state, action) => {
      state.loading = true;
    },
    [logout.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = "";
      state.token = "";
      state.user = null;
      window.localStorage.clear();
    },
  },
});

export default authReducer.reducer;
